import * as Yup from "yup"

const VALID_EMAIL = "Please input a valid email"
const REQUIRED_MESSAGE = "This field is required."
const VALID_MOBILE_NUMBER =
  "Please input a valid mobile number in this format: 09991234567"

export const updatedPersonalDetailsValidationSchema = (client) => {
  let defaultValidationSchema = {
    email: Yup.string().when("fieldsToUpdate", {
      is: (fieldsToUpdate) => fieldsToUpdate?.includes("Email"),
      then: Yup.string().email(VALID_EMAIL).required(REQUIRED_MESSAGE),
    }),
    mobileNumber: Yup.string().when("fieldsToUpdate", {
      is: (fieldsToUpdate) => fieldsToUpdate?.includes("Mobile Number"),
      then: Yup.string()
        .required(REQUIRED_MESSAGE)
        .min(11, VALID_MOBILE_NUMBER)
        .max(11, VALID_MOBILE_NUMBER)
        .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER),
    }),
  }

  if (client.toLowerCase() === "foundever") {
    defaultValidationSchema.siteAddress = Yup.object().when("fieldsToUpdate", {
      is: (fieldsToUpdate) => fieldsToUpdate?.includes("Site Address"),
      then: Yup.object().shape({
        value: Yup.string().required(REQUIRED_MESSAGE),
        label: Yup.string().required(REQUIRED_MESSAGE),
      }),
    })
  }

  if (client.toLowerCase() === "accenture") {
    defaultValidationSchema.deliveryAddress = Yup.object().when(
      "fieldsToUpdate",
      {
        is: (fieldsToUpdate) => fieldsToUpdate?.includes("Delivery Address"),
        then: Yup.object().shape({
          houseNumber: Yup.string()
            .matches(/.*\S.*/, REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE),
          streetAddress: Yup.string()
            .matches(/.*\S.*/, REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE),
          province: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
            label: Yup.string().required(REQUIRED_MESSAGE),
          }),
          city: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
            label: Yup.string().required(REQUIRED_MESSAGE),
          }),
          notes: Yup.string().matches(
            /.*\S.*/,
            "Please enter a valid delivery note"
          ),
        }),
      }
    )
  }

  if (client.toLowerCase() === "maxicare") {
    defaultValidationSchema.deliveryAddress = Yup.object().when(
      "fieldsToUpdate",
      {
        is: (fieldsToUpdate) => fieldsToUpdate?.includes("Delivery Address"),
        then: Yup.object().shape({
          addressType: Yup.string()
            .matches(/.*\S.*/, REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE),
          houseNumber: Yup.string().when("addressType", {
            is: (addressType) => addressType === "Home",
            then: Yup.string()
              .matches(/.*\S.*/, REQUIRED_MESSAGE)
              .required(REQUIRED_MESSAGE),
          }),
          officeAddress: Yup.object().when("addressType", {
            is: (addressType) => addressType === "Office",
            then: Yup.object().shape({
              value: Yup.string().required(REQUIRED_MESSAGE),
              label: Yup.string().required(REQUIRED_MESSAGE),
            }),
          }),
          streetAddress: Yup.string().when("addressType", {
            is: (addressType) => addressType === "Home",
            then: Yup.string()
              .matches(/.*\S.*/, REQUIRED_MESSAGE)
              .required(REQUIRED_MESSAGE),
          }),
          province: Yup.object().when("addressType", {
            is: (addressType) => addressType === "Home",
            then: Yup.object().shape({
              value: Yup.string().required(REQUIRED_MESSAGE),
              label: Yup.string().required(REQUIRED_MESSAGE),
            }),
          }),
          city: Yup.object().when("addressType", {
            is: (addressType) => addressType === "Home",
            then: Yup.object().shape({
              value: Yup.string().required(REQUIRED_MESSAGE),
              label: Yup.string().required(REQUIRED_MESSAGE),
            }),
          }),
          barangay: Yup.object().when("addressType", {
            is: (addressType) => addressType === "Home",
            then: Yup.object().shape({
              value: Yup.string().required(REQUIRED_MESSAGE),
              label: Yup.string().required(REQUIRED_MESSAGE),
            }),
          }),

          notes: Yup.string().matches(
            /.*\S.*/,
            "Please enter a valid delivery note"
          ),
        }),
      }
    )
  }

  return Yup.object(defaultValidationSchema)
}
